const jspack = require('jspack').jspack;
const Mavlink20 = require('./mavlink20');

class CmdReadFileList extends Mavlink20 {
  format = '<BBB100s';
  msg_id = 100100;
  order_map = [0, 1, 2, 3];
  crc_extra = 250;
  name = 'CMD_READ_FILE_LIST';
  sum = 0;

  id = 0;
  // 0 背景音, 1 录音, 2 实时语音, 3 TTS, 4 缓存文件, 5
  channel = 0;

  constructor(channel = 1, id = 0, sum = 0, name = 'CMD_READ_FILE_LIST') {
    super();
    this.channel = channel;
    this.id = id;
    this.sum = sum;
    this.name = name;
  }

  pack() {
    return super.pack(
      this,
      this.crc_extra,
      jspack.Pack(this.format, [this.channel, this.id, this.sum, this.name])
    );
  }
}

module.exports = CmdReadFileList;

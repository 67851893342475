<template>
  <div class="PagerP1Box" style="pointer-events: auto">
    <div v-interact class="PagerP1TitleBox jcsb">
      <div class="left ml8">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="cp">{{ title }}</div>
      </div>
      <div class="cp close mr16" @click="close">{{ exit }}</div>
    </div>
    <div class="PagerP1AreaBox">
      <div class="leftArea">
        <AngleControl :volume="volume" :rotate="angle" />
      </div>
      <div class="rightArea">
        <div class="w329 h231 mt17 rightAreaBox">
          <div class="jcsb h24 tc lh24">
            <div
              v-for="item in ItemList"
              :key="item.id"
              class="w160"
              :class="type == item.name ? 'active' : 'rightAreaNav'"
              @click="type = item.name"
            >
              {{ item.title }}
            </div>
          </div>
          <div>
            <component :is="type" @playAudio="playAudio" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MavLink20Processor from '@/utils/PagerP1/mavlink20_processor';
import AngleControl from './components/angleControl'; // p1喊话器
import ItemA from './components/itemA'; // 文字转语音
import ItemB from './components/itemB'; // 语音广播
import ItemC from './components/itemC'; // 音频文件

// 必须引入的核心，换成require也是一样的。注意：recorder-core会自动往window下挂载名称为Recorder对象，全局可调用window.Recorder，也许可自行调整相关源码清除全局污染
import Recorder from 'recorder-core';
import { mapGetters } from 'vuex';
const { VUE_APP_FCMQTT_URL } = process.env;
// 需要使用到的音频格式编码引擎的js文件统统加载进来
import 'recorder-core/src/engine/mp3';
import 'recorder-core/src/engine/mp3-engine';
import 'recorder-core/src/engine/pcm';
import mqtt from 'mqtt';
export default {
  // watch: {
  //   'PoliceKeyCode': {
  //     handler(value) {
  //       if(value){
  //         this.key = value;
  //       }
  //       this.init();
  //     },
  //     deep: true,
  //     immediate:true
  //   },
  // },
  components: {
    AngleControl,
    ItemA,
    ItemB,
    ItemC
  },
  props: {
    title: {
      default: '喊话器',
      type: String
    },
    exit: {
      default: '关闭',
      type: String
    },
    PoliceKeyCode: {
      default: 'MMC301R22205057',
      type: String
    }
  },
  data() {
    return {
      type: 'ItemA',
      music: {
        list: []
      },
      // ttsState: null,
      key: 'PoliceF737',
      ItemList: [
        {
          id: 1,
          name: 'ItemA',
          title: '文字转语音'
        },
        {
          id: 2,
          name: 'ItemB',
          title: '语音广播'
        },
        {
          id: 3,
          name: 'ItemC',
          title: '音频文件'
        }
      ],
      mqtt: {
        client: null,
        decoder: null,
        encoder: new TextEncoder()
      },
      ttsState: null,
      recodeState: null,
      volume: 0,
      angle: null,
      ttsCycle: null,
      recodeCycle: null
    };
  },
  provide() {
    return {
      PagerP1: this,
      PoliceKey: () => this.PoliceKeyCode
    };
  },
  computed: {
    ...mapGetters(['user_info'])
  },
  mounted() {
    this.init();
  },
  methods: {
    playAudio(val) {
      this.$emit('playAudio', val);
    },
    init() {
      const url = VUE_APP_FCMQTT_URL;
      // let url = 'ws://200.10.1.245:8883/mqtt'
      console.log('测试开始连接');
      this.mqtt.client = mqtt.connect(url, {
        protocol: 'mqtt',
        clientId: 3587
      });
      this.mqtt.client.on('connect', () => {
        console.log('mqtt is connected');
      });
      this.mqtt.client.on('reconnect', function (reconnect) {
        console.log(reconnect, 'reconnect');
      });
      const key = this.PoliceKeyCode;
      const topic = 'SPEAK/RECEIVE/${key}-01';
      console.log(topic, '链接主题');
      this.subscribe(`SPEAK/RECEIVE/${key}-01`, () => {
        console.log(`订阅无人机数据：topic:${topic}`);
      });
      const music_list = [];
      this.mqtt.client.on('message', (topic, payload, packet) => {
        const data = JSON.parse(packet.payload.toString());
        console.log(data, 'musicdata');
        this.music.list = data.musicList;
        this.ttsState = data.ttsState;
        this.recodeState = data.recodeState;
        this.volume = data.status_info.volume || 0;
        this.angle = data.angle;
        this.ttsCycle = data.ttsCycle;
        this.recodeCycle = data.recodeCycle;
      });
    },
    subscribe(topic, callback) {
      if (!topic) {
        return topic;
      }
      const { client } = this.mqtt;
      if (client) {
        console.log('订阅数据a', topic);
        client.subscribe(topic, callback);
      }
    },
    close() {
      this.$emit('close');
      this.mqtt.client.end();
      this.mqtt.client = null;
    }
  }
};
</script>
<style lang="scss" scoped>
.PagerP1Box {
  position: fixed;
  // top: 70px;
  top: 300px;
  right: 70px;
  box-sizing: border-box;
  padding: 10px 20px;
  width: 510px;
  min-height: 296px;
  .PagerP1TitleBox {
    height: 32px;
    line-height: 32px;
    background: linear-gradient(180deg, #9198ff 0%, rgba(45, 81, 153, 0.45) 40%, #05091a 100%);
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;
    .left {
      display: flex;
      .cp {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .close {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
    }
  }
  .PagerP1AreaBox {
    display: flex;
    // justify-content: space-around;
    height: 311px;
    background: rgba(24, 39, 76, 0.7);
    .leftArea {
      // width: 204px;
    }
    .rightArea {
      // width: calc(100% - 204px);
      // background-color:aquamarine
      .rightAreaBox {
        // border-radius: 4px 4px 0 0;
        .rightAreaNav {
          border: 1px solid #3768b3;
          background: rgba(23, 70, 216, 0.3);
          // box-shadow: 0px 1px 3px 0px rgba(23, 33, 60, 0.5),
          //   inset 0px 0px 10px 0px rgba(33, 137, 255, 0.4),
          //   inset 0px 0px 3px 0px #00a7ff;
          border: 1px solid;
          border-image: linear-gradient(180deg, rgba(138, 218, 255, 1), rgba(82, 179, 255, 0)) 1 1;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          color: #44c5fd;
        }
        .active {
          background: #2647ee;
          box-shadow: 0 2px 4px 0 rgba(23, 33, 60, 0.5), inset 0 0 4px 0 #00a7ff,
            inset 0 0 16px 0 rgba(33, 137, 255, 0.4);
          cursor: pointer;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
        }
      }
    }
  }
}
</style>

const jspack = require('jspack').jspack;
const Mavlink20 = require('./mavlink20');

class CmdTts extends Mavlink20 {
  msg_id = 100085;
  order_map = [0, 1];
  crc_extra = 253;
  name = 'CMD_TTS_TEXT';
  result = null;
  text;

  constructor(text, format = '<B250s') {
    super();
    this.text = text;
    this.format = format;
  }

  pack() {
    return super.pack(this, this.crc_extra, jspack.Pack(this.format, [this.result, this.text]));
  }
}

module.exports = CmdTts;

// let tts = new CmdTts("hello world")

// // console.log("tts:", tts);

// console.log("tts:", tts.pack());

const jspack = require('jspack').jspack;
const Mavlink20 = require('./mavlink20');

class CmdRecordState extends Mavlink20 {
  format = '<BB';
  msg_id = 100095;
  order_map = [0, 1];
  crc_extra = 132;
  // srcSystem = 255;
  // srcComponent = 190;
  state = 11;
  name = 'CMD_RECORD_STATE';
  result = null;
  constructor(state) {
    super();
    this.state = state;
  }
  pack() {
    return super.pack(this, this.crc_extra, jspack.Pack(this.format, [this.state, this.result]));
  }
}
module.exports = CmdRecordState;

<template>
  <div
    v-loading="loading"
    class="itemCMaxBox"
    element-loading-text="音频上传中..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.4)"
  >
    <div class="jcsb cf mt24">
      <span>音频列表:({{ PagerP1.music.list.length || 0 }}条)</span>
      <div class="w15 h15 iconfont c40d icon-gengxin cp" @click="reset" />
    </div>
    <div class="w315 h98 itemCList">
      <div
        v-for="(item, index) in PagerP1.music.list"
        :key="index"
        :class="activeBg == index ? 'bg148' : 'bg149'"
        class="h26 lh26 jcsb pl10 pr10 cf cp"
        @click="handClick(item, index)"
      >
        <div class="List">
          <span class="mr15">{{ index + 1 }}</span>
          <span>{{ item }}</span>
        </div>
        <div class="del" @click="del(item)">
          <span class="iconfont icon-shanchu" />
        </div>
      </div>
    </div>
    <div class="bottomBtnBox tc jcsb mt10 pr24">
      <div class="cf f14">
        <div class="w30 h30 btnStyle lh35 tc" :class="scflag ? 'pointerEvents' : ''">
          <div class="w15 h15 ml8 iconfont pr icon-a-shangchuan2px">
            <input
              id="btn_file"
              ref="btn_file"
              class="pa top0 cp w30 h30"
              type="file"
              accept="audio/*"
              @change="handleUploadMusicFile"
            />
          </div>
        </div>
        <div v-if="!scflag">上传</div>
        <div v-else>上传中...</div>
      </div>
      <div class="cf f14 ml5">
        <div class="w30 h30 btnStyle cp tc" @click="handlePlayMusic">
          <div v-if="videoFlag" class="w5 h5 mt7 ml8 iconfont icon-a-bofang2px" />
          <div v-else class="w20 h20 mt7 ml5 iconfont icon-a-zanting2px" />
        </div>
        <div>播放</div>
      </div>
      <!-- <div class="cf f14" v-if="itemName" @click="delSong">
        <div class="w30 h30 btnStyle cp">
          <div class="w5 h5 mt10 ml9 iconfont icon-shanchu"></div>
        </div>
        <div>{{$t('operation.Delete')}}</div>
      </div>-->
      <div class="cf f14 mt20 ml10">
        <span>播放模式:</span>
        <el-select
          v-model="recodeCycle"
          size="mini"
          class="dib w100"
          placeholder="请选择"
          popper-append-to-body
          @change="onChange"
        >
          <el-option
            v-for="item in options"
            :key="item.recodeCycle"
            :label="item.type"
            :value="item.recodeCycle"
          />
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import MavLink20Processor from '@/utils/PagerP1/mavlink20_processor';
import CmdTtsText from '@/utils/PagerP1/cmd_tts_text';
import CmdReadFileList from '@/utils/PagerP1/cmd_read_file_list';
import CmdRecordName from '@/utils/PagerP1/cmd_record_name';
import CmdSetSystemVolume from '@/utils/PagerP1/cmd_set_system_volume';
import CmdStreamFunction from '@/utils/PagerP1/cmd_stream_function';
import CmdStreamUpData from '@/utils/PagerP1/cmd_stream_up_data';
import CmdSaveFile from '@/utils/PagerP1/cmd_save_file';
import CmdRecordStateA from '@/utils/PagerP1/cmd_record_state';

export default {
  data() {
    return {
      activeBg: null,
      itemName: '',
      videoFlag: true,
      status: 11,
      scflag: false,
      oldName: '',
      loading: false,
      options: [
        {
          recodeCycle: 10,
          type: '单曲'
        },
        {
          recodeCycle: 11,
          type: '单曲循环'
        }
        // {
        //   recodeCycle: 12,
        //   type: "列表",
        // },
        // {
        //   recodeCycle: 13,
        //   type: "列表循环",
        // },
        // {
        //   recodeCycle: 14,
        //   type: "随机",
        // },
        // {
        //   recodeCycle: -1,
        //   type: "未初始化",
        // },
      ],
      // recodeState: 10,
      recodeCycle: 10,
      action: null,
      result: null,
      types: null,
      e: null
    };
  },
  watch: {
    PagerP1: {
      handler(val) {
        if (val.recodeState == 11) {
          this.videoFlag = false;
        } else {
          this.videoFlag = true;
        }
      }
    },
    action: {
      handler(val) {
        this.upload(this.e);
      }
    }
  },
  created() {
    // // 获取列表
    this.handleGetMusicList();
  },
  mounted() {
    setInterval(() => {
      this.recodeCycle = this.PagerP1.recodeCycle;
      if (this.PagerP1.recodeState == 11) {
        this.videoFlag = false;
      } else {
        this.videoFlag = true;
      }
    }, 100);
  },
  methods: {
    reset() {
      const res = {
        data: '',
        type: 2323
      };
      this.mqtt_publish(res);
      this.$message.success('已刷新');
    },
    del(item) {
      this.$confirm('是否删除此音乐？', '确认', {
        cancelButtonText: this.$t('operation.Cancel'),
        confirmButtonText: this.$t('operation.Confirm'),
        // customClass: "uav_controlPane",
        showClose: false
      })
        .then(() => {
          const message = {
            data: item,
            type: 2324
          };
          this.mqtt_publish(message);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });
        });
    },
    onChange(val) {
      const message = {
        data: val,
        type: 2310
      };
      this.mqtt_publish(message);
    },
    handClick(item, index) {
      this.activeBg = index;
      this.itemName = item;
      // this.stopMusic();
      // this.startMusic()
      this.videoFlag = true;
    },
    startMusic() {
      const message = {
        data: this.itemName,
        type: 2308
      };
      this.mqtt_publish(message);
      this.oldName = this.itemName;
      this.videoFlag = false;
    },
    handlePlayMusic() {
      /**
       * 10 停止
       * 11 播放
       * 12 暂停
       * 13 询问状态
       * 14
       */
      if (!this.itemName) return this.$message.info('未选择曲目');
      if (this.itemName.indexOf('$') == 0) {
        return this.$message.warning('此歌曲正在增响中，刷新后重试！');
      }
      if (this.itemName != this.oldName) {
        this.startMusic();
      } else {
        if (this.videoFlag) {
          this.status = 11;
          // this.videoFlag = false;
          this.$emit('playAudio', 26);
        } else {
          this.status = 12;
          // this.videoFlag = true;
        }
        const message = {
          data: this.status,
          type: 2309
        };
        this.videoFlag = !this.videoFlag;
        this.oldName = this.itemName;
        this.mqtt_publish(message);
      }
    },
    stopMusic() {
      const message = {
        data: 12,
        type: 2309
      };
      this.mqtt_publish(message);
      this.videoFlag = true;
    },
    handleGetMusicList() {
      // let cmdReadFileList = new CmdReadFileList();
      // let message = cmdReadFileList.pack();
      // message = Uint8Array.from(message);
      // this.mqtt_publish(message);
    },
    // 删除单个文件
    delSong() {
      const data = {
        data: this.itemName,
        type: 2323
      };
      this.mqtt_publish(data);
    },
    // 上传文件
    handleUploadMusicFile(e) {
      this.e = e;
      const start = {
        data: 10,
        type: 2322
      };
      this.mqtt_publish(start);
      console.log('流上传开始:', start);
    },
    async upload(e) {
      console.log('upload', e);
      const file = e.target.files[0];
      const name = file.name.replace(' ', '');
      try {
        if (this.PagerP1.music.list.length > 0) {
          this.PagerP1.music.list.forEach((val) => {
            if (val == name) {
              throw new Error('退出循环');
            }
          });
        }

        // 状态更改
        this.scflag = true;
        this.loading = true;
        if (this.action == 10 && this.result == 0 && this.types == 1) {
          await this.main(file);
          const end = {
            data: 11,
            type: 2322
          };
          this.mqtt_publish(end);
        }

        // 保存
        if (this.action == 11 && this.result == 0 && this.types == 1) {
          const end = {
            data: 11,
            type: 2322
          };
          this.mqtt_publish(end);
          const message = {
            data: file.name.replace(' ', ''),
            type: 2320
          };
          await this.mqtt_publish(message);
          this.$message.success('上传成功!');
          const res = {
            data: '',
            type: 2323
          };
          this.mqtt_publish(res);
          this.scflag = false;
          this.loading = false;
          document.querySelector('#btn_file').value = '';
        }
      } catch (e) {
        if (e.message === '退出循环') {
          this.$message.error('上传的歌曲与列表中歌曲存在相同名字，请重试！');
          document.querySelector('#btn_file').value = '';
        }
      }
    },
    async sleep(time) {
      return new Promise((res) => setTimeout(res, time));
    },
    async main(file) {
      const arrayBuffer = await this.blob2arraybuffer(file);
      const fileBuffer = Array.from(new Uint8Array(arrayBuffer));
      const slice_size = 2500;
      const total_count = Math.ceil(fileBuffer.length / slice_size);
      console.log(total_count);
      for (let i = 0; i < total_count; i++) {
        await this.sleep(10);
        // setTimeout(() => {
        // console.log("流上传:", i);
        const buffer = fileBuffer.splice(0, slice_size);
        this.s(buffer);
        // }, 50);
      }
    },
    s(buffer) {
      const message = {
        data: buffer,
        type: 2318
      };
      this.mqtt_publish(message);
    },
    async mqtt_publish(message) {
      if (this.PoliceKey()) {
        const key = await this.PoliceKey();

        this.PagerP1.mqtt.client.publish(
          `SPEAK/OBTAIN/${key}-01`,
          new TextEncoder().encode(JSON.stringify(message)),
          {
            qos: 0
          },
          (e) => {
            console.log('发送成功', message);
          }
        );
        this.PagerP1.mqtt.client.on('message', (topic, payload, packet) => {
          const data = JSON.parse(packet.payload.toString());
          this.PagerP1.recodeState = data.recodeState;
          this.action = data.action;
          this.result = data.result;
          this.types = data.type;
        });
      }
    },

    async blob2arraybuffer(blob) {
      if (!blob) {
        return Promise.reject(blob);
      }
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = () => resolve(fileReader.result);
        fileReader.onerror = (e) => reject(e);
        fileReader.readAsArrayBuffer(blob);
      });
    }
  },
  inject: ['PagerP1', 'PoliceKey']
};
</script>

<style lang="scss" scoped>
.itemCMaxBox {
  padding: 0 8px;
  border: 1px solid #315ec7;
  input[type='file'] {
    color: transparent;
    opacity: 0;
    top: 0px;
    left: -7px;
    width: 20px;
    cursor: pointer !important;
    text-decoration: none;
  }
  .itemCList {
    border: 1px solid #3360a6;
    overflow-y: auto;
    border-radius: 10px;
    .List {
      width: 360px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .bottomBtnBox {
    .btnStyle {
      border-radius: 50%;

      background: #9bacbe;
      color: #3e495b;
      border: 10px solid #000;
      .bf {
        transform: rotate(90deg);
      }
    }
    .btnStyle:hover {
      color: #43deff;
    }
  }
}
::v-deep el-select {
  .el-input--mini {
    background: #3768b3;
  }
}
.c40d {
  color: #40d4f5;
}
.bg148 {
  opacity: 1;
  background: #315ec7;
  color: #ffffff;
  border-radius: 2px;
  border-radius: 5px;
}
.bg149 {
  opacity: 0.5;
}
.pointerEvents {
  pointer-events: none;
}
::v-deep .el-select {
  .el-input__inner {
    border: 1px solid #3768b3;
    background: #132550;
    border-radius: 4px 4px 0 0;
    color: #fff;
  }
}
</style>

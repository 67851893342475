const jspack = require('jspack').jspack;
const Mavlink20 = require('./mavlink20');

class CmdSetSystemVolume extends Mavlink20 {
  format = '<BB';
  msg_id = 100113;
  order_map = [0, 1];
  crc_extra = 144;
  name = 'CMD_SET_SYSTEM_VOLUME';
  result = null;

  constructor(volume = 100) {
    super();
    this.volume = volume;
  }

  pack() {
    return super.pack(this, this.crc_extra, jspack.Pack(this.format, [this.volume, this.result]));
  }
}

module.exports = CmdSetSystemVolume;
